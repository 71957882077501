import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  getAgentSignup,
  regenerateAgentLink,
  cancelAgentLink,
  getSmtpSettings,
  addSmtpSettings,
  getPaymentSettings,
  addPaymentSettings,
  verifySmtpSettings,
  getQuotationSettings,
  addQuotationSettings,
  getCommissionSettings,
  addCommissionSettings,
  getClientNotes,
  editClientNotes,
  getSignatorySettings,
  deleteSignatorySettings,
} from "./../../../config/reseller_api_calls";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  AGENT_SERVER_URL,
  APP_LIVE_URL,
  REACT_APP_AGENT_API_URL,
} from "./../../../config/config";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import PageTitle from "../ExtraComponents/PageTitle";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import Link from "@material-ui/core/Link";
// import { Editor } from "@tinymce/tinymce-react";
import dateFormat, { masks } from "dateformat";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Upgrades extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
      payment_reg_button: false,
      quotation_button: false,
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commissionErrorTitle: "",
      commission_button: false,
      commission_type: "paid_on_collected",
      commission_value_type: "percentage",
      apply_commission: "agent_account",
      commission_value: "",
      bank_account_details: "",
      wire_transfer_details: "",
      quotation_notes: "",
      payment_terms: "",
      selected_payment_terms: null,
      currency: "",
      decimalPoint: 2,
      selected_currency: null,
      contact_person: "",
      contact_email: "",
      validity: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      smtp_host: "",
      smtp_user: "",
      smtp_password: "",
      smtp_method: "tls",
      smtp_port: "",
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
      from_email: "",
      errorFromEmail: "",
      is_working: "",
      notifications_email: "",
      billing_email: "",
      sales_email: "",
      settings_type: "",
      clientNotes: "",
      otherSettingSuccessMessage: "",
      otherSettingErrorMessage: "",
      client_setting_button: false,
      //Signatory Settings
      signatory_name: "",
      signatory_company: "",
      signatory_title: "",
      signatory_signature_image: "",
      signatory_status: "",
      signatorySettingSuccessMessage: "",
      signatorySettingErrorMessage: "",
      signatorySettingErrorTitle: "",
      signatorySettingNotFound: "",
      signatory_button: false,
      signatoryDetails: [],
      columnsignatoryDetails: [],
      add_picture: "",
      add_picture_name: "",
      signatory_button_delete: false,
      signatory_id: "",
      signatoryDeleteSuccessMessage: "",
      signatoryDeleteErrorMessage: "",
      signatoryDeleteErrorTitle: "",
      signatoryDeleteNotFound: "",
      signatory_button_delete: false,
      isEditorReady: false,
    };
  }

  async componentDidMount() {
    //settings_type
    let settings_type = this.props.props.match.params.settings_type;
    console.log("settings_type: ", settings_type);
    this.setState({
      settings_type: settings_type,
    });
    if (settings_type === "smtp_settings") {
      this.get_smtp_settings();
    }
    if (settings_type === "payment_settings") {
      this.get_payment_settings();
    }
    if (settings_type === "quotation_settings") {
      this.get_quotation_settings();
    }
    if (settings_type === "commission_settings") {
      this.get_commission_settings();
    }
    if (settings_type === "other_settings") {
      this.get_other_settings();
    }
    if (settings_type === "signatory_settings") {
      this.get_signatory_settings();
    }
    const servicesResponce = await getAgentSignup(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        login_url: servicesResponce.data.login_url,
        logo: servicesResponce.data.logo,
        signature_image: servicesResponce.data.signature,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  regenerate_link = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await regenerateAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link generated successfully.",
        reg_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  cancel_link = async () => {
    this.setState({
      cancel_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await cancelAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("cancel_link: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link removed successfully.",
        cancel_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  get_smtp_settings = async () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getSmtpSettings(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("get_smtp_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        smtp_host: servicesResponce.data.data[0].smtp_host,
        smtp_user: servicesResponce.data.data[0].smtp_user,
        smtp_password: servicesResponce.data.data[0].smtp_password,
        smtp_method: servicesResponce.data.data[0].smtp_method,
        smtp_port: servicesResponce.data.data[0].smtp_port,
        from_email: servicesResponce.data.data[0].from_email,
        notifications_email: servicesResponce.data.data[0].notifications_email,
        is_working: servicesResponce.data.data[0].is_working,
        isEditorReady: true,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "no_record"
    ) {
      this.setState({
        smtp_host: "",
        smtp_user: "",
        smtp_password: "",
        smtp_method: "tls",
        smtp_port: "",
        from_email: "",
        notifications_email: "",
        is_working: "no",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
      notifications_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    }
    if (notifications_email === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Notifications email is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (notifications_email !== "") {
      if (this.validateEmailAdd(notifications_email) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid notifications email format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      const servicesResponce = await addSmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email,
        notifications_email
      );
      //console.log("addSmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings added successfully.",
          smtp_reg_button: false,
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  //Test SMTP Settings

  verify_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_test_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      const servicesResponce = await verifySmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email
      );
      console.log("verifySmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings tested successfully.",
          smtp_test_button: false,
          is_working: "yes",
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  get_payment_settings = async () => {
    this.setState({
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
    });
    const servicesResponce = await getPaymentSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_payment_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        paymentErrorMessage:
          "There is some error while getting payment settings.",
        paymentSuccessMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        bank_account_details: servicesResponce.data.bank_details,
        wire_transfer_details: servicesResponce.data.wire_transfer_details,
        isEditorReady: true,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        bank_account_details: "",
        wire_transfer_details: "",
      });
    } else {
      this.setState({
        paymentErrorMessage:
          "There is some error while getting payment settings.",
        paymentSuccessMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_payment_settings = async () => {
    let { bank_account_details, wire_transfer_details } = this.state;
    this.setState({
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
      payment_reg_button: true,
    });
    var is_validated = true;

    if (bank_account_details === "") {
      this.setState({
        paymentErrorMessage: "Payment details are required.",
        payment_reg_button: false,
      });
      is_validated = false;
    }
    /*if (wire_transfer_details === "") {
      this.setState({
        paymentErrorMessage: "Wire transfer details are required.",
        payment_reg_button: false,
      });
      is_validated = false;
    }*/

    if (is_validated) {
      const servicesResponce = await addPaymentSettings(
        auth.getAccount(),
        auth.getToken(),
        bank_account_details,
        wire_transfer_details
      );
      console.log("add_payment_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          paymentErrorMessage:
            "There is some error while adding payment settings.",
          paymentSuccessMessage: "",
          payment_reg_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          paymentErrorMessage: "",
          paymentSuccessMessage: "Payment settings added successfully.",
          payment_reg_button: false,
        });
      } else {
        this.setState({
          paymentErrorMessage:
            "There is some error while adding payment settings.",
          paymentSuccessMessage: "",
          payment_reg_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ paymentErrorMessage: "", paymentSuccessMessage: "" });
    }, 5000);
  };

  get_other_settings = async () => {
    this.setState({
      clientNotes: "",
      otherSettingSuccessMessage: "",
      otherSettingErrorMessage: "",
      client_setting_button: false,
      tableLoader: true,
    });
    const notesResponce = await getClientNotes(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("Client Notes  Res: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      this.setState({
        clientNotes: notesResponce.data.data.welcome_content,
        tableLoader: false,
        client_setting_button: false,
        isEditorReady: true,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  saveClientNotes = async () => {
    window.scrollTo(0, 0);
    const clientNotes = this.state.clientNotes;
    var validated = true;
    if (clientNotes === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        otherSettingErrorMessage: "Please enter the required field.",
        otherSettingSuccessMessage: "",
      });
    } else {
      this.setState({
        otherSettingErrorMessage: "",
        otherSettingSuccessMessage: "",
        disabled: true,
        client_setting_button: true,
      });

      const saveResponce = await editClientNotes(
        auth.getAccount(),
        auth.getToken(),
        clientNotes
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          otherSettingErrorMessage:
            "There is some error while updating the Content.",
          otherSettingSuccessMessage: "",
          disabled: false,
          client_setting_button: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          otherSettingErrorMessage: "",
          otherSettingSuccessMessage: "Content updated successfully.",
          disabled: false,
          client_setting_button: false,
        });
        setTimeout(() => {
          this.setState({
            otherSettingErrorMessage: "",
            otherSettingSuccessMessage: "",
            disabled: false,
            client_setting_button: false,
          });
          this.get_other_settings();
        }, 3000);
      } else {
        this.setState({
          otherSettingErrorMessage: saveResponce.data.message,
          otherSettingSuccessMessage: "",
          disabled: false,
          client_setting_button: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  handleEditorClientNotes = (event, editor) => {
    const content = editor.getData();
    this.setState({
      clientNotes: content,
    });
    //console.log("Content was updated:", content);
  };

  // handleEditorClientNotes = (content, editor) => {
  //   this.setState({
  //     clientNotes: content,
  //   });
  // };

  getInitialSelectedOption = (value) => {
    // Retrieve the initial selected option from your options array
    let options = this.getOption(); // Replace with your actual options array

    if (options) {
      return options.find((option) => option.value === value) || null;
    }
  };

  getInitialSelectedTermOption = (value) => {
    // Retrieve the initial selected option from your options array
    let options = this.getTermOptions(); // Replace with your actual options array

    if (options) {
      return options.find((option) => option.value === value) || null;
    }
  };

  getOption = () => {
    const currencyOptions = [
      { value: "usd", label: "USD - US Dollar" },
      { value: "eur", label: "EUR - Euro" },
      { value: "gbp", label: "GBP - British Pound" },
      { value: "jpy", label: "JPY - Japanese Yen" },
      { value: "cny", label: "CNY - Chinese Yuan" },
      { value: "inr", label: "INR - Indian Rupee" },
      { value: "aud", label: "AUD - Australian Dollar" },
      { value: "cad", label: "CAD - Canadian Dollar" },
      { value: "chf", label: "CHF - Swiss Franc" },
      { value: "sek", label: "SEK - Swedish Krona" },
      { value: "nzd", label: "NZD - New Zealand Dollar" },
      { value: "sgd", label: "SGD - Singapore Dollar" },
      { value: "hkd", label: "HKD - Hong Kong Dollar" },
      { value: "krw", label: "KRW - South Korean Won" },
      { value: "brl", label: "BRL - Brazilian Real" },
      { value: "zar", label: "ZAR - South African Rand" },
      { value: "rub", label: "RUB - Russian Ruble" },
      { value: "try", label: "TRY - Turkish Lira" },
      { value: "mxn", label: "MXN - Mexican Peso" },
      { value: "idr", label: "IDR - Indonesian Rupiah" },
    ];
    return currencyOptions;
  };

  getTermOptions = () => {
    const currencyOptions = [
      { value: "Net 30", label: "Net 30" },
      { value: "Net 60", label: "Net 60" },
      { value: "Net 90", label: "Net 90" },
      { value: "Due on Receipt", label: "Due on Receipt" },
      { value: "2/10 Net 30", label: "2/10 Net 30" },
      { value: "1/10 Net 30", label: "1/10 Net 30" },
      { value: "2/10 Net 60", label: "2/10 Net 60" },
      { value: "3/10 Net 30", label: "3/10 Net 30" },
      { value: "Net 45", label: "Net 45" },
      { value: "Net 120", label: "Net 120" },
      { value: "Cash on Delivery (COD)", label: "Cash on Delivery (COD)" },
      { value: "Cash in Advance (CIA)", label: "Cash in Advance (CIA)" },
      { value: "Stage Payments", label: "Stage Payments" },
      { value: "End of Month (EOM)", label: "End of Month (EOM)" },
    ];
    return currencyOptions;
  };

  get_quotation_settings = async () => {
    this.setState({
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
    });
    const servicesResponce = await getQuotationSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_quotation_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        quotationErrorMessage:
          "There is some error while getting quotation settings.",
        quotationSuccessMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        quotation_notes:
          servicesResponce.data.quotationSettings[0]["quotation_notes"],
        payment_terms:
          servicesResponce.data.quotationSettings[0]["payment_terms"],
        selected_payment_terms: this.getInitialSelectedTermOption(
          servicesResponce.data.quotationSettings[0]["payment_terms"]
        ),
        currency: servicesResponce.data.quotationSettings[0]["currency"],
        selected_currency: this.getInitialSelectedOption(
          servicesResponce.data.quotationSettings[0]["currency"]
        ),
        contact_person:
          servicesResponce.data.quotationSettings[0]["contact_person"],
        contact_email:
          servicesResponce.data.quotationSettings[0]["contact_email"],
        validity: servicesResponce.data.quotationSettings[0]["validity"],
        decimalPoint:
          servicesResponce.data.quotationSettings[0]["decimal_point"],
        isEditorReady: true,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        quotation_notes: "",
        payment_terms: "",
        currency: "",
        contact_person: "",
        contact_email: "",
        validity: "",
      });
    } else {
      this.setState({
        quotationErrorMessage:
          "There is some error while getting quotation settings.",
        quotationSuccessMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_quotation_settings = async () => {
    let {
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      decimalPoint,
    } = this.state;
    this.setState({
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
      quotation_button: true,
    });
    var is_validated = true;

    /*if (quotation_notes === "") {
      this.setState({
        quotationErrorMessage: "Quotation notes are required.",
        quotation_button: false,
      });
      is_validated = false;
    } else if (payment_terms === "") {
      this.setState({
        quotationErrorMessage: "Payment terms are required.",
        quotation_button: false,
      });
      is_validated = false;
    }*/
    if (decimalPoint < 2 || decimalPoint > 6) {
      this.setState({
        quotationErrorMessage:
          "Please enter a number between 2 and 6 for decimal points.",
        quotation_button: false,
      });
      is_validated = false;
    }

    if (is_validated) {
      const servicesResponce = await addQuotationSettings(
        auth.getAccount(),
        auth.getToken(),
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
        decimalPoint
      );
      console.log("add_quotation_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          quotationErrorMessage:
            "There is some error while adding quotation settings.",
          quotationSuccessMessage: "",
          quotation_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        Cookies.remove("decimalPoint__");
        Cookies.set(
          "decimalPoint__",
          CryptoJS.AES.encrypt(
            decimalPoint.toString(),
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        this.setState({
          quotationErrorMessage: "",
          quotationSuccessMessage: "Quotation settings added successfully.",
          quotation_button: false,
        });
      } else {
        this.setState({
          quotationErrorMessage:
            "There is some error while adding quotation settings.",
          quotationSuccessMessage: "",
          quotation_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ quotationErrorMessage: "", quotationSuccessMessage: "" });
    }, 5000);
  };

  get_commission_settings = async () => {
    this.setState({
      commissionErrorTitle: "",
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
    });
    const servicesResponce = await getCommissionSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_commission_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        commissionErrorTitle: "System Error",
        commissionErrorMessage:
          "There is some error while getting commission settings.",
        commissionSuccessMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        commission_type:
          servicesResponce.data.commissionSettings[0]["commission_type"],
        commission_value_type:
          servicesResponce.data.commissionSettings[0]["commission_value_type"],
        commission_value:
          servicesResponce.data.commissionSettings[0]["commission_value"],
        apply_commission:
          servicesResponce.data.commissionSettings[0]["apply_commission"],
        isEditorReady: true,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        commission_value: "",
      });
    } else {
      this.setState({
        commissionErrorTitle: "System Error",
        commissionErrorMessage:
          "There is some error while getting commission settings.",
        commissionSuccessMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_commission_settings = async () => {
    let {
      commission_type,
      commission_value_type,
      commission_value,
      apply_commission,
    } = this.state;
    this.setState({
      commissionErrorTitle: "",
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commission_button: true,
    });
    var is_validated = true;

    if (commission_value === "") {
      this.setState({
        commissionErrorTitle: "Missing Field",
        commissionErrorMessage: "Commission value is required.",
        commission_button: false,
      });
      is_validated = false;
    }

    if (is_validated) {
      const servicesResponce = await addCommissionSettings(
        auth.getAccount(),
        auth.getToken(),
        commission_type,
        commission_value_type,
        commission_value,
        apply_commission
      );
      console.log("add_quotation_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionErrorMessage:
            "There is some error while adding commission settings.",
          commissionSuccessMessage: "",
          commission_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          commissionErrorTitle: "",
          commissionErrorMessage: "",
          commissionSuccessMessage: "Commission settings added successfully.",
          commission_button: false,
        });
      } else {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionErrorMessage:
            "There is some error while adding commission settings.",
          commissionSuccessMessage: "",
          commission_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        commissionErrorMessage: "",
        commissionErrorTitle: "",
        commissionSuccessMessage: "",
      });
    }, 5000);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        currency: changed_value.value,
        selected_currency: changed_value,
      }));
    } else {
      this.setState({ selected_currency: null });
    }
  };

  handleChangeSearchTerms = async (changed_value) => {
    console.log("handleChangeSearchTerms: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        payment_terms: changed_value.value,
        selected_payment_terms: changed_value,
      }));
    } else {
      this.setState({ selected_payment_terms: null });
    }
  };

  handleEditorBank = (event, editor) => {
    const content = editor.getData();
    this.setState({
      bank_account_details: content,
    });
    //console.log("Content was updated:", content);
  };

  // handleEditorBank = (content, editor) => {
  //   this.setState({
  //     bank_account_details: content,
  //   });
  //   //console.log("Content was updated:", content);
  // };

  handleEditorQuotationNotes = (event, editor) => {
    const content = editor.getData();
    this.setState({
      quotation_notes: content,
    });
    //console.log("Content was updated:", content);
  };

  // handleEditorQuotationNotes = (content, editor) => {
  //   this.setState({
  //     quotation_notes: content,
  //   });
  //   //console.log("Content was updated:", content);
  // };

  get_signatory_settings = async () => {
    this.setState({
      signatorySettingSuccessMessage: "",
      signatorySettingErrorMessage: "",
      signatorySettingNotFound: "",
      signatory_button: false,
      tableLoader: true,
    });
    const notesResponce = await getSignatorySettings(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("get_signatory_settings: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      this.setState({
        signatoryDetails: notesResponce.data.data,
        tableLoader: false,
        isEditorReady: true,
        columnsignatoryDetails: [
          {
            name: "name",
            label: "Name",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "title",
            label: "Title",
          },
          {
            name: "company",
            label: "Company",
          },
          {
            name: "signature_image",
            label: "Signature",
            options: {
              customBodyRender: (value, tableMeta) => {
                return <div>{this.showSignature(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "date_added",
            label: "Date Added",
            options: {
              customBodyRender: (value) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },

          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip
                                    title="Delete Signatory"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.deleteSignatory(value)
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em class="icon ni ni-trash"></em>
                                      <span>Delete</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      //window.location.replace("/error");
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  showSignature = (value, tableMeta) => {
    let signature = tableMeta.rowData[3];
    if (signature !== "") {
      signature = APP_LIVE_URL + "files_data/nda_signatures/" + signature;
      return (
        <div>
          <img style={{ width: "200px" }} src={signature}></img>
        </div>
      );
    } else {
      return "---";
    }
  };

  addSignatoryForm = async () => {
    this.setState({
      signatorySettingSuccessMessage: "",
      signatorySettingErrorMessage: "",
      signatorySettingErrorTitle: "",
      signatorySettingNotFound: "",
      signatory_button: false,
    });
    window.$("#modalAddSignatory").modal("show");
  };

  fieldValidationUsername = () => {
    let {
      signatory_name,
      signatory_company,
      signatory_title,
      add_picture_name,
    } = this.state;

    if (signatory_name === "") {
      this.setState({
        signatorySettingErrorMessage: "Signatory name is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_company === "") {
      this.setState({
        signatorySettingErrorMessage: "Signatory company is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_title === "") {
      this.setState({
        signatorySettingErrorMessage: "Signatory title is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (add_picture_name === "") {
      this.setState({
        signatorySettingErrorMessage: "Signatory signature is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else {
      this.setState({
        signatorySettingErrorMessage: "",
        signatorySettingErrorTitle: "",
        disabled: true,
      });
    }

    return true;
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        signatorySettingErrorMessage:
          "Invalid file type. Please select a valid file format.",
        signatorySettingErrorTitle: "Invalid Type",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        signatorySettingErrorMessage:
          "File size exceeds the limit (5MB). Please choose a smaller file.",
        signatorySettingErrorTitle: "Size limit exceeded",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // Image dimension validation
    const imageReader = new FileReader();

    imageReader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;

      // Define dimensions based on image type
      let maxWidth, maxHeight, minWidth, minHeight;

      maxWidth = 300;
      maxHeight = 66;
      minWidth = 200;
      minHeight = 44;

      // Wait for the image to be fully loaded before checking dimensions
      image.onload = () => {
        if (image.width > maxWidth || image.height > maxHeight) {
          this.setState({
            signatorySettingErrorMessage: `Image dimensions exceed the limit (${maxWidth}x${maxHeight}). Please choose a smaller image.`,
            signatorySettingErrorTitle: "Invalid Height/Width",
            add_picture: "",
            add_picture_name: "",
          });
        } else if (image.width < minWidth || image.height < minHeight) {
          this.setState({
            signatorySettingErrorMessage: `Image dimensions should be at least (${minWidth}x${minHeight}). Please choose a larger image.`,
            signatorySettingErrorTitle: "Invalid Height/Width",
            add_picture: "",
            add_picture_name: "",
          });
        } else {
          // Clear any previous errors
          this.setState({
            add_picture: selectedFile,
            signatorySettingErrorMessage: "",
            add_picture_name: selectedFile.name,
          });
        }
      };
    };

    imageReader.readAsDataURL(selectedFile);
  };

  submitSignatory = async () => {
    let {
      signatory_name,
      signatory_company,
      signatory_title,
      signatory_status,
      add_picture,
      add_picture_name,
    } = this.state;
    this.setState({
      signatory_button: true,
    });
    if (this.fieldValidationUsername() === true) {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("file", add_picture);
      formData.append("fileName", add_picture_name);
      formData.append("signatory_name", signatory_name);
      formData.append("signatory_company", signatory_company);
      formData.append("signatory_title", signatory_title);
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "resellers/submit_signatory",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                signatorySettingErrorMessage: "Record not found.",
                signatorySettingErrorTitle: "System Error",
                signatory_button: false,
                signatorySettingSuccessMessage: "",
              });
            } else if (response.data.status === 403) {
              this.setState({
                signatorySettingErrorTitle: "System Error",
                signatorySettingErrorMessage: response.data.message,
                signatory_button: false,
                signatorySettingSuccessMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                signatorySettingSuccessMessage: "Signatory added successfully.",
                signatorySettingErrorMessage: "",
                signatorySettingErrorTitle: "",
                showLoginButton: true,
              });

              setTimeout(() => {
                this.get_signatory_settings();
                window.$("#modalAddSignatory").modal("hide");
              }, 3000);
            } else {
              this.setState({
                signatorySettingErrorTitle: "System Error",
                signatorySettingErrorMessage:
                  "There is some error while adding the signatory.",
                signatorySettingSuccessMessage: "",
                signatory_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          signatorySettingErrorTitle: "System Error",
          signatorySettingErrorMessage:
            "There is some error while adding the signatory.",
          disabled: false,
          successMessage: "",
          signatory_button: false,
        });

        console.log("catch : ", error);
      }
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
    }
  };

  deleteSignatory = async (signatory_id) => {
    this.setState({
      signatory_id: signatory_id,
      signatoryDeleteSuccessMessage: "",
      signatoryDeleteErrorMessage: "",
      signatoryDeleteErrorTitle: "",
      signatoryDeleteNotFound: "",
      signatory_button_delete: false,
    });
    window.$("#modalDeleteSignatory").modal("show");
  };

  closeDeleteSignatory = () => {
    window.$("#modalDeleteSignatory").modal("hide");
  };

  submitDeleteSignatory = async () => {
    const { signatory_id } = this.state;
    this.setState({
      signatoryDeleteSuccessMessage: "",
      signatoryDeleteErrorMessage: "",
      signatoryDeleteErrorTitle: "",
      signatoryDeleteNotFound: "",
      signatory_button_delete: true,
    });
    const notesResponce = await deleteSignatorySettings(
      auth.getAccount(),
      auth.getToken(),
      signatory_id
    );

    console.log("get_signatory_settings: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      notesResponce.data.status === 404 &&
      notesResponce.data.message === "not_found"
    ) {
      this.setState({
        signatoryDeleteSuccessMessage: "",
        signatoryDeleteErrorMessage: "Signatory not found.",
        signatoryDeleteErrorTitle: "Not Found",

        signatory_button_delete: false,
      });
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      this.setState({
        signatoryDeleteSuccessMessage: "Signatory deleted successfully.",
        signatoryDeleteErrorMessage: "",
        signatoryDeleteErrorTitle: "",
        signatory_button_delete: false,
      });
      setTimeout(() => {
        this.get_signatory_settings();
        window.$("#modalDeleteSignatory").modal("hide");
      }, 3000);
    } else {
      this.setState({
        signatoryDeleteSuccessMessage: "",
        signatoryDeleteErrorMessage:
          "There is some error while deleting the signatory.",
        signatoryDeleteErrorTitle: "Error",

        signatory_button_delete: false,
      });
    }
  };

  handleEditorWire = (content, editor) => {
    this.setState({
      wire_transfer_details: content,
    });
    //console.log("Content was updated:", content);
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  render() {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          REACT_APP_AGENT_API_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em className="icon ni ni-setting"></em> Settings
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p> &nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                <div className="col-xxl-12">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "general_settings" ||
                              this.state.settings_type === ""
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem5"
                          >
                            <em className="icon ni ni-setting"></em>
                            <span>General Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "smtp_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem6"
                            onClick={() => this.get_smtp_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>SMTP Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "payment_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem7"
                            onClick={() => this.get_payment_settings()}
                          >
                            <em className="icon ni ni-cc-alt"></em>
                            <span>Payment Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "quotation_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItemQuotation"
                            onClick={() => this.get_quotation_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>Quotation Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "commission_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItemCommission"
                            onClick={() => this.get_commission_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>Commission Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "other_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#other_settings"
                            onClick={() => this.get_other_settings()}
                          >
                            <em className="icon ni ni-setting"></em>
                            <span>Other Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "signatory_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#signatory_settings"
                            onClick={() => this.get_signatory_settings()}
                          >
                            <em className="icon ni ni-contact"></em>
                            <span>Signatory Settings</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={
                            this.state.settings_type === "general_settings" ||
                            this.state.settings_type === ""
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem5"
                        >
                          <div className="row g-gs">
                            <div className="col-xxl-6">
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-user"></em>{" "}
                                      Channel Partner Signup Link
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <>
                                        {this.state.errorMessage !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>{this.state.errorMessage}</p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessage !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {this.state.successMessage}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="example-alert">
                                          <div className="alert alert-light alert-icon">
                                            <em className="icon ni ni-alert-circle"></em>{" "}
                                            <strong>NOTE: </strong> Anyone with
                                            this link will be able to create a
                                            super channel partner for this
                                            reseller. Be very careful where and
                                            how you send this! It is also a good
                                            idea to make sure you have any
                                            supporting documents and contracts
                                            in place before you allow the
                                            reseller to have its first super
                                            channel partner.{" "}
                                          </div>
                                        </div>

                                        <div
                                          className="caption-text"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-light">
                                              <em className="icon ni ni-link"></em>{" "}
                                              {this.state.signup_link &&
                                              this.state.signup_link !== "" ? (
                                                <>{this.state.signup_link}</>
                                              ) : (
                                                <>
                                                  Channel partner signup link is
                                                  not available. Please create a
                                                  new one by clicking on
                                                  "Regenerate" button.
                                                </>
                                              )}
                                            </div>
                                          </div>
                                          {this.state.reg_button === false ? (
                                            <button
                                              className="btn btn-primary"
                                              style={{ marginRight: "15px" }}
                                              onClick={() =>
                                                this.regenerate_link()
                                              }
                                            >
                                              <em className="icon ni ni-swap"></em>{" "}
                                              Regenerate
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary"
                                              style={{ marginRight: "15px" }}
                                              disabled
                                            >
                                              <em className="icon ni ni-swap"></em>{" "}
                                              Regenerate
                                              <div
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            </button>
                                          )}

                                          {this.state.cancel_button ===
                                          false ? (
                                            <button
                                              className="btn btn-danger"
                                              style={{ marginRight: "15px" }}
                                              onClick={() => this.cancel_link()}
                                            >
                                              <em className="icon ni ni-cross-round"></em>{" "}
                                              Cancel
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-danger"
                                              style={{ marginRight: "15px" }}
                                              disabled
                                            >
                                              <em className="icon ni ni-cross-round"></em>{" "}
                                              Cancel
                                              <div
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            </button>
                                          )}
                                          {this.state.signup_link &&
                                          this.state.signup_link !== "" ? (
                                            <CopyToClipboard
                                              text={this.state.signup_link}
                                              onCopy={() =>
                                                this.setState({ copied: true })
                                              }
                                            >
                                              <button className="btn btn-success">
                                                <em className="icon ni ni-copy"></em>{" "}
                                                Copy to clipboard{" "}
                                                {this.state.copied ? (
                                                  <span
                                                    style={{ color: "white" }}
                                                  >
                                                    <em className="icon ni ni-done"></em>
                                                  </span>
                                                ) : null}
                                              </button>
                                            </CopyToClipboard>
                                          ) : null}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-signin"></em>{" "}
                                      Channel Partner Login URL
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    <div className="example-alert">
                                      <div
                                        className="alert alert-light alert-icon"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        <em className="icon ni ni-link"></em>{" "}
                                        {this.state.login_url &&
                                        this.state.login_url !== "" ? (
                                          <>{this.state.login_url}</>
                                        ) : (
                                          <>
                                            Channel partner login link is not
                                            available. Please create a new one
                                            by clicking on "Regenerate" button.
                                          </>
                                        )}
                                      </div>
                                      {/*{this.state.reg_button_login === false ? (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                onClick={() => this.regenerate_link()}
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                disabled
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            )}*/}
                                      {this.state.login_url &&
                                      this.state.login_url !== "" ? (
                                        <CopyToClipboard
                                          text={this.state.login_url}
                                          onCopy={() =>
                                            this.setState({ copied2: true })
                                          }
                                        >
                                          <button className="btn btn-success">
                                            <em className="icon ni ni-copy"></em>{" "}
                                            Copy to clipboard{" "}
                                            {this.state.copied2 ? (
                                              <span style={{ color: "white" }}>
                                                <em className="icon ni ni-done"></em>
                                              </span>
                                            ) : null}
                                          </button>
                                        </CopyToClipboard>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6">
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-camera"></em>{" "}
                                      Upload Logo and Signature
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <>
                                        <div className="nk-block">
                                          <div className="card card-bordered">
                                            <div className="card-inner">
                                              <h5 className="card-title">
                                                <em className="icon ni ni-camera"></em>{" "}
                                                Upload Logo
                                              </h5>
                                              <h6 className="card-subtitle mb-2">
                                                &nbsp;
                                              </h6>
                                              <Fileupload
                                                image_type="logo"
                                                logo_image={this.state.logo}
                                                reseller_accountno={auth.getAccount()}
                                              ></Fileupload>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-block">
                                          <div className="card card-bordered">
                                            <div className="card-inner">
                                              <h5 className="card-title">
                                                <em className="icon ni ni-camera"></em>{" "}
                                                Upload Signature
                                              </h5>
                                              <h6 className="card-subtitle mb-2">
                                                &nbsp;
                                              </h6>
                                              <Fileupload
                                                image_type="signature"
                                                logo_image={
                                                  this.state.signature_image
                                                }
                                                reseller_accountno={auth.getAccount()}
                                              ></Fileupload>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.settings_type === "smtp_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem6"
                        >
                          <div className="row g-gs">
                            {this.state.SmtpErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.SmtpErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.SmtpSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.SmtpSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.is_working === "no" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-warning">
                                    <div className="alert-text">
                                      <h4>Warning</h4>
                                      <p>
                                        Your SMTP details are not verified.
                                        Please click "Test SMTP Settings" to
                                        verify the details.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Host or ip address of your smtp server (example: smtp.company.com)"
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    SMTP Host{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_host"
                                    name="smtp_host"
                                    type="text"
                                    label="SMTP Host"
                                    value={this.state.smtp_host}
                                    onChange={this.handleChange}
                                    error={this.state.errorHostName}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Very important: What kind of encryption to use on the SMTP connection."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Security Type{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="smtp_method"
                                    name="smtp_method"
                                    select
                                    label="Security Type"
                                    value={this.state.smtp_method}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setstls1" value="tls">
                                      TLS
                                    </option>
                                    <option key="setssl1" value="ssl">
                                      SSL
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Username for authentication."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Username{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_user"
                                    name="smtp_user"
                                    type="text"
                                    label="SMTP User"
                                    value={this.state.smtp_user}
                                    onChange={this.handleChange}
                                    error={this.state.errorUser}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Password for authentication."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_password"
                                    name="smtp_password"
                                    type="text"
                                    label="SMTP Password"
                                    value={this.state.smtp_password}
                                    error={this.state.errorPassword}
                                    onChange={this.handleChange}
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The default port is 25, but some SMTP servers use a custom port (example: 587,465)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Port <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="smtp_port"
                                    name="smtp_port"
                                    select
                                    label="Port"
                                    value={this.state.smtp_port}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="port25" value="25">
                                      25
                                    </option>
                                    <option key="port465" value="465">
                                      465
                                    </option>
                                    <option key="port587" value="587">
                                      587
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The sender email address (example: account@example.com)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Email From{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="from_email"
                                    name="from_email"
                                    type="text"
                                    label="Email From"
                                    value={this.state.from_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorFromEmail}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The email address to receive the notifications (example: account@example.com)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Notifications Email{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="notifications_email"
                                    name="notifications_email"
                                    type="text"
                                    label="Notifications Email"
                                    value={this.state.notifications_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorFromEmail}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.smtp_test_button === false ? (
                                  <button
                                    type="button"
                                    className={
                                      this.state.is_working === "yes"
                                        ? "btn btn-lg btn-success"
                                        : "btn btn-lg btn-primary"
                                    }
                                    onClick={() => this.verify_smtp_settings()}
                                    style={{ marginRight: "15px" }}
                                    disabled={this.state.disabled}
                                  >
                                    {this.state.is_working === "yes" ? (
                                      <>
                                        <em class="icon ni ni-done"></em>
                                        SMTP Settings Verified
                                      </>
                                    ) : (
                                      "Test SMTP Settings"
                                    )}
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Test
                                    SMTP Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                                {this.state.smtp_reg_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() => this.add_smtp_settings()}
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.settings_type === "payment_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem7"
                        >
                          <div className="row g-gs">
                            {this.state.paymentErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.paymentErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.paymentSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.paymentSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-lg-12">
                              <h6>Payment Details</h6>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                {this.state.isEditorReady ? (
                                  <>
                                    <CKEditor
                                      onReady={(editor) => {
                                        const editableElement =
                                          editor.ui.getEditableElement();
                                        if (
                                          editableElement &&
                                          editableElement.parentElement
                                        ) {
                                          editableElement.parentElement.insertBefore(
                                            editor.ui.view.toolbar.element,
                                            editableElement
                                          );
                                        }

                                        this.editor = editor;
                                      }}
                                      onError={(
                                        error,
                                        { willEditorRestart }
                                      ) => {
                                        if (willEditorRestart) {
                                          if (
                                            this.editor &&
                                            this.editor.ui &&
                                            this.editor.ui.view &&
                                            this.editor.ui.view.toolbar
                                          ) {
                                            this.editor.ui.view.toolbar.element.remove();
                                          }
                                        }
                                      }}
                                      onChange={(event, editor) =>
                                        this.handleEditorBank(event, editor)
                                      }
                                      editor={DecoupledEditor}
                                      data={this.state.bank_account_details}
                                      config={{
                                        cloudServices: {
                                          tokenUrl: fetchToken,
                                          uploadUrl:
                                            REACT_APP_AGENT_API_URL +
                                            "agents/editor_image_upload",
                                        },
                                        toolbar: {
                                          items: [
                                            "undo",
                                            "redo",
                                            "htmlSupport",
                                            "|",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strikethrough",
                                            "|",
                                            "fontFamily",
                                            "fontSize",
                                            "fontColor",
                                            "fontBackgroundColor",
                                            "|",
                                            "alignment",
                                            "indent",
                                            "outdent",
                                            "|",
                                            "numberedList",
                                            "bulletedList",
                                            "|",
                                            "link",
                                            "imageUpload",
                                            "insertTable",
                                            "|",
                                            "codeBlock",
                                            "blockQuote",
                                            "|",
                                            "removeFormat",
                                            "htmlEmbed",
                                            "fullscreen",
                                          ],
                                        },
                                        language: "en",
                                      }}
                                    />
                                  </>
                                ) : null}
                                {/* <Editor
                                  id="bank_account_details"
                                  name="bank_account_details"
                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                  value={this.state.bank_account_details}
                                  selector="textarea#full-featured"
                                  init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    ],
                                    toolbar:
                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                      images_upload_handler: (blobInfo, success, failure) => {
                                        const formData = new FormData();
                                        formData.append("image", blobInfo.blob());
    
                                        fetch(AGENT_SERVER_URL + "agents/editor_image_upload", {
                                          method: "POST",
                                          body: formData,
                                        })
                                          .then((response) => {
                                            if (response.ok) {
                                              return response.json();
                                            } else {
                                              throw new Error("Failed to upload image");
                                            }
                                          })
                                          .then((data) => {
                                            if (data.url) {
                                              success(data.url);
                                            } else {
                                              throw new Error("Image upload failed: No URL received");
                                            }
                                          })
                                          .catch((error) => {
                                            console.error("Image upload failed:", error);
                                            failure("Image upload failed");
                                          });
                                      }   
                                  }}
                                  onEditorChange={this.handleEditorBank}
                                /> */}
                              </div>
                            </div>
                            {/*<div className="col-lg-12">
                              <h6>Wire Transfer</h6>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                <Editor
                                  id="wire_transfer_details"
                                  name="wire_transfer_details"
                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                  value={this.state.wire_transfer_details}
                                  selector="textarea#full-featured"
                                  init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    ],
                                    toolbar:
                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                      images_upload_handler: (blobInfo, success, failure) => {
                                        const formData = new FormData();
                                        formData.append("image", blobInfo.blob());
    
                                        fetch(AGENT_SERVER_URL + "agents/editor_image_upload", {
                                          method: "POST",
                                          body: formData,
                                        })
                                          .then((response) => {
                                            if (response.ok) {
                                              return response.json();
                                            } else {
                                              throw new Error("Failed to upload image");
                                            }
                                          })
                                          .then((data) => {
                                            if (data.url) {
                                              success(data.url);
                                            } else {
                                              throw new Error("Image upload failed: No URL received");
                                            }
                                          })
                                          .catch((error) => {
                                            console.error("Image upload failed:", error);
                                            failure("Image upload failed");
                                          });
                                      }   
                                  }}
                                  onEditorChange={this.handleEditorWire}
                                />
                              </div>
                            </div>*/}
                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.payment_reg_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() => this.add_payment_settings()}
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/**** Quotation Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "quotation_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemQuotation"
                        >
                          <div className="row g-gs">
                            {this.state.quotationErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.quotationErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.quotationSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.quotationSuccessMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Preferred terms of payment (Net 30, Net 60, 50% Deposit, COD (Cash on Delivery))."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Terms of payment{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.getTermOptions()}
                                    name="payment_terms"
                                    placeholder="Please select the preferred currency"
                                    value={this.state.selected_payment_terms}
                                    autoComplete="off"
                                    emptyMessage="Terms not found"
                                    onChange={this.handleChangeSearchTerms}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "40px",
                                      }),
                                      menu: (baseStyles) => ({
                                        ...baseStyles,
                                        zIndex: 9999, // Set the same or a higher z-index value
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The currency in which you would like to receive the payment."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Currency{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.getOption()}
                                    name="selected_currency"
                                    placeholder="Please select the preferred currency"
                                    value={this.state.selected_currency}
                                    autoComplete="off"
                                    emptyMessage="Currency not found"
                                    onChange={this.handleChangeSearch}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "40px",
                                      }),
                                      menu: (baseStyles) => ({
                                        ...baseStyles,
                                        zIndex: 9999, // Set the same or a higher z-index value
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Contact Person{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="contact_person"
                                    name="contact_person"
                                    type="text"
                                    label="Provide the full name"
                                    value={this.state.contact_person}
                                    onChange={this.handleChange}
                                    error={this.state.errorcontact_person}
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Contact Email{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="contact_email"
                                    name="contact_email"
                                    type="text"
                                    label="Provide the email"
                                    value={this.state.contact_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorcontact_email}
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Specify the number of days for which this quotation will remain valid."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Validity (Days){" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="validity"
                                    name="validity"
                                    type="text"
                                    label="Quotation Validity"
                                    value={this.state.validity}
                                    onChange={this.handleChange}
                                    error={this.state.errorvalidity}
                                    inputProps={{
                                      maxLength: 4, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Specify the number of decimal points to be displayed for service rates. Enter a number between 2 and 6."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Decimal Points{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="decimalPoint"
                                    name="decimalPoint"
                                    type="number"
                                    value={this.state.decimalPoint}
                                    onChange={this.handleChange}
                                    error={this.state.errordecimalPoint}
                                    inputProps={{
                                      max: 6,
                                      min: 2,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <h6>Quotation Notes</h6>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                {this.state.isEditorReady ? (
                                  <>
                                    <CKEditor
                                      onReady={(editor) => {
                                        const editableElement =
                                          editor.ui.getEditableElement();
                                        if (
                                          editableElement &&
                                          editableElement.parentElement
                                        ) {
                                          editableElement.parentElement.insertBefore(
                                            editor.ui.view.toolbar.element,
                                            editableElement
                                          );
                                        }

                                        this.editor = editor;
                                      }}
                                      onError={(
                                        error,
                                        { willEditorRestart }
                                      ) => {
                                        if (willEditorRestart) {
                                          if (
                                            this.editor &&
                                            this.editor.ui &&
                                            this.editor.ui.view &&
                                            this.editor.ui.view.toolbar
                                          ) {
                                            this.editor.ui.view.toolbar.element.remove();
                                          }
                                        }
                                      }}
                                      onChange={(event, editor) =>
                                        this.handleEditorQuotationNotes(
                                          event,
                                          editor
                                        )
                                      }
                                      editor={DecoupledEditor}
                                      data={this.state.quotation_notes}
                                      config={{
                                        cloudServices: {
                                          tokenUrl: fetchToken,
                                          uploadUrl:
                                            REACT_APP_AGENT_API_URL +
                                            "agents/editor_image_upload",
                                        },
                                        toolbar: {
                                          items: [
                                            "undo",
                                            "redo",
                                            "htmlSupport",
                                            "|",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strikethrough",
                                            "|",
                                            "fontFamily",
                                            "fontSize",
                                            "fontColor",
                                            "fontBackgroundColor",
                                            "|",
                                            "alignment",
                                            "indent",
                                            "outdent",
                                            "|",
                                            "numberedList",
                                            "bulletedList",
                                            "|",
                                            "link",
                                            "imageUpload",
                                            "insertTable",
                                            "|",
                                            "codeBlock",
                                            "blockQuote",
                                            "|",
                                            "removeFormat",
                                            "htmlEmbed",
                                            "fullscreen",
                                          ],
                                        },
                                        language: "en",
                                      }}
                                    />
                                  </>
                                ) : null}
                                {/* <Editor
                                  id="quotation_notes"
                                  name="quotation_notes"
                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                  value={this.state.quotation_notes}
                                  selector="textarea#full-featured"
                                  init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    ],
                                    toolbar:
                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                      images_upload_handler: (blobInfo, success, failure) => {
                                        const formData = new FormData();
                                        formData.append("image", blobInfo.blob());
    
                                        fetch(AGENT_SERVER_URL + "agents/editor_image_upload", {
                                          method: "POST",
                                          body: formData,
                                        })
                                          .then((response) => {
                                            if (response.ok) {
                                              return response.json();
                                            } else {
                                              throw new Error("Failed to upload image");
                                            }
                                          })
                                          .then((data) => {
                                            if (data.url) {
                                              success(data.url);
                                            } else {
                                              throw new Error("Image upload failed: No URL received");
                                            }
                                          })
                                          .catch((error) => {
                                            console.error("Image upload failed:", error);
                                            failure("Image upload failed");
                                          });
                                      }   
                                  }}
                                  onEditorChange={this.handleEditorQuotationNotes}
                                /> */}
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group"></div>
                            </div>
                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.quotation_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() =>
                                      this.add_quotation_settings()
                                    }
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/**** Quotation Settings ****/}
                        {/**** Commission Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "commission_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemCommission"
                        >
                          <div className="row g-gs">
                            {this.state.commissionErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>{this.state.commissionErrorTitle}</h4>
                                      <p>{this.state.commissionErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.commissionSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.commissionSuccessMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Set the default value of how the commission will be applied."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Apply Commission{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="apply_commission"
                                    name="apply_commission"
                                    select
                                    label=""
                                    value={this.state.apply_commission}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setst1" value="agent_account">
                                      Channel Partner Account
                                    </option>
                                    <option
                                      key="setst2"
                                      value="product_services"
                                    >
                                      Product & Services
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Billed Commission: Paid when the sale is invoiced or billed to the customer, regardless of whether the payment has been received. Collected Commission: Paid only when the payment from the customer is collected. This can be more common in industries where payment collection might be a prolonged process."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Commission Payment Terms{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="commission_type"
                                    name="commission_type"
                                    select
                                    label=""
                                    value={this.state.commission_type}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setst1" value="paid_on_billed">
                                      Paid On Billed
                                    </option>
                                    <option
                                      key="setst2"
                                      value="paid_on_collected"
                                    >
                                      Paid On Collected
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Agents receive a fixed or percentage based on the profit margin."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Commission Type{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="commission_value_type"
                                    name="commission_value_type"
                                    select
                                    label=""
                                    value={this.state.commission_value_type}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setst33" value="fixed">
                                      Fixed
                                    </option>
                                    <option key="setst23" value="percentage">
                                      Percentage
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Commission Value{" "}
                                    {this.state.commission_value_type ===
                                    "percentage"
                                      ? "(%)"
                                      : ""}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="commission_value"
                                    name="commission_value"
                                    type="text"
                                    label=""
                                    value={this.state.commission_value}
                                    onChange={this.handleChange}
                                    onKeyPress={() => {
                                      this.checkNumber();
                                    }}
                                    inputProps={{
                                      maxLength: 9, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group"></div>
                            </div>
                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.commission_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() =>
                                      this.add_commission_settings()
                                    }
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/**** Commission Settings ****/}
                        {/**** Other Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "other_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="other_settings"
                        >
                          <div className="row g-gs">
                            {this.state.otherSettingErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.otherSettingErrorMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.otherSettingSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.otherSettingSuccessMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-lg-12">
                              <h6>Client Notes </h6>
                            </div>
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <>
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    {this.state.isEditorReady ? (
                                      <>
                                        <CKEditor
                                          onReady={(editor) => {
                                            const editableElement =
                                              editor.ui.getEditableElement();
                                            if (
                                              editableElement &&
                                              editableElement.parentElement
                                            ) {
                                              editableElement.parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editableElement
                                              );
                                            }

                                            this.editor = editor;
                                          }}
                                          onError={(
                                            error,
                                            { willEditorRestart }
                                          ) => {
                                            if (willEditorRestart) {
                                              if (
                                                this.editor &&
                                                this.editor.ui &&
                                                this.editor.ui.view &&
                                                this.editor.ui.view.toolbar
                                              ) {
                                                this.editor.ui.view.toolbar.element.remove();
                                              }
                                            }
                                          }}
                                          onChange={(event, editor) =>
                                            this.handleEditorClientNotes(
                                              event,
                                              editor
                                            )
                                          }
                                          editor={DecoupledEditor}
                                          data={this.state.clientNotes}
                                          config={{
                                            cloudServices: {
                                              tokenUrl: fetchToken,
                                              uploadUrl:
                                                REACT_APP_AGENT_API_URL +
                                                "agents/editor_image_upload",
                                            },
                                            toolbar: {
                                              items: [
                                                "undo",
                                                "redo",
                                                "htmlSupport",
                                                "|",
                                                "bold",
                                                "italic",
                                                "underline",
                                                "strikethrough",
                                                "|",
                                                "fontFamily",
                                                "fontSize",
                                                "fontColor",
                                                "fontBackgroundColor",
                                                "|",
                                                "alignment",
                                                "indent",
                                                "outdent",
                                                "|",
                                                "numberedList",
                                                "bulletedList",
                                                "|",
                                                "link",
                                                "imageUpload",
                                                "insertTable",
                                                "|",
                                                "codeBlock",
                                                "blockQuote",
                                                "|",
                                                "removeFormat",
                                                "htmlEmbed",
                                                "fullscreen",
                                              ],
                                            },
                                            language: "en",
                                          }}
                                        />
                                      </>
                                    ) : null}
                                    {/* <Editor
                                      id="clientNotes"
                                      name="clientNotes"
                                      apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                      value={this.state.clientNotes}
                                      selector="textarea#full-featured"
                                      init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                          "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                        ],
                                        toolbar:
                                          "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                          images_upload_handler: (blobInfo, success, failure) => {
                                            const formData = new FormData();
                                            formData.append("image", blobInfo.blob());
        
                                            fetch(AGENT_SERVER_URL + "agents/editor_image_upload", {
                                              method: "POST",
                                              body: formData,
                                            })
                                              .then((response) => {
                                                if (response.ok) {
                                                  return response.json();
                                                } else {
                                                  throw new Error("Failed to upload image");
                                                }
                                              })
                                              .then((data) => {
                                                if (data.url) {
                                                  success(data.url);
                                                } else {
                                                  throw new Error("Image upload failed: No URL received");
                                                }
                                              })
                                              .catch((error) => {
                                                console.error("Image upload failed:", error);
                                                failure("Image upload failed");
                                              });
                                          }   
                                      }}
                                      onEditorChange={
                                        this.handleEditorClientNotes
                                      }
                                    /> */}
                                  </div>
                                </div>
                                <div className="col-md-12 text-right">
                                  <div className="form-group">
                                    {this.state.client_setting_button ===
                                    false ? (
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={() => this.saveClientNotes()}
                                        disabled={this.state.disabled}
                                      >
                                        Save Other Settings
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-lg btn-primary"
                                        style={{ marginRight: "15px" }}
                                        disabled
                                      >
                                        <em className="icon ni ni-swap"></em>{" "}
                                        Save Other Settings
                                        <div
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {/**** Other Settings ****/}
                        {/**** Other Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "signatory_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="signatory_settings"
                        >
                          <div className="row g-gs">
                            <div className="col-lg-12">
                              <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">
                                      <PageTitle
                                        name="Signatories"
                                        icon="icon ni ni-user-list"
                                      />
                                    </h3>
                                  </div>
                                  <div
                                    className="nk-block-head-content"
                                    id="HeadContentAgent"
                                  >
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em className="icon ni ni-more-v"></em>
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                      >
                                        <ul className="nk-block-tools g-3">
                                          <li className="nk-block-tools-opt">
                                            <Link
                                              color="inherit"
                                              onClick={() =>
                                                this.addSignatoryForm()
                                              }
                                              className="btn btn-primary"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                color: "#fff",
                                              }}
                                            >
                                              <em className="icon ni ni-plus-round"></em>
                                              &nbsp;Add Signatory
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <>
                                {this.state.signatorySettingNotFound !== "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <p>
                                            {
                                              this.state
                                                .signatorySettingNotFound
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.signatoryDetails &&
                                this.state.signatoryDetails.length > 0 ? (
                                  <div className="col-md-12">
                                    <DataTableExtended
                                      columns={
                                        this.state.columnsignatoryDetails
                                      }
                                      tableData={this.state.signatoryDetails}
                                      title=""
                                    />
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                        {/**** Signatory Settings ****/}
                        {/***** Add Signatory Form *****/}
                        <div
                          class="modal fade"
                          tabindex="-1"
                          id="modalAddSignatory"
                        >
                          <div
                            class="modal-dialog modal-xl modal-dialog-top"
                            role="document"
                          >
                            <div class="modal-content">
                              <a
                                href="#"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                              <div class="modal-header">
                                <h5 class="modal-title">Add New Signatory</h5>
                              </div>
                              <div class="modal-body">
                                <div className="row g-gs">
                                  {this.state.signatorySettingErrorMessage !==
                                  "" ? (
                                    <div className="col-md-12">
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-danger">
                                          <div className="alert-text">
                                            <h4>
                                              {
                                                this.state
                                                  .signatorySettingErrorTitle
                                              }
                                            </h4>
                                            <p>
                                              {
                                                this.state
                                                  .signatorySettingErrorMessage
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.signatorySettingSuccessMessage !==
                                  "" ? (
                                    <div className="col-md-12">
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-success">
                                          <div className="alert-text">
                                            <h4>Success</h4>
                                            <p>
                                              {
                                                this.state
                                                  .signatorySettingSuccessMessage
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Name of the signatory."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Name <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_name"
                                          name="signatory_name"
                                          type="text"
                                          label="Signatory Name"
                                          value={this.state.signatory_name}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 50, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Title of the signatory."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Title <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_title"
                                          name="signatory_title"
                                          type="text"
                                          label="Signatory Title"
                                          value={this.state.signatory_title}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Company name of the signatory."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Company Name <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_company"
                                          name="signatory_company"
                                          type="text"
                                          label="Signatory Company"
                                          value={this.state.signatory_company}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 50, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Signature image of the signatory."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Upload Signature <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group signatory_upload">
                                        <div class="custom-file">
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="add_picture"
                                            name="add_picture"
                                            defaultValue={
                                              this.state.add_picture
                                            }
                                            accept=".png, .jpg, .jpeg"
                                            onChange={this.handleFileChange}
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="add_picture"
                                          >
                                            Choose file
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mt-2">
                                    <div className="example-alert">
                                      <div className="alert alert-light">
                                        <ul className="list">
                                          <li>
                                            Accepted Formats: PNG, JPEG, JPG
                                          </li>
                                          <li>Recommended Size: 5MB</li>
                                          <li>
                                            <b>Recommended Dimensions:</b>
                                            <br /> Maximum (W:300 x H:66)
                                            <br /> Minimum (W:200 x H:44)
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="modal-footer"
                                style={{ justifyContent: "right" }}
                              >
                                <div class="d-flex justify-content-end">
                                  {this.state.signatory_button === true ? (
                                    <button
                                      className="btn btn-lg btn-primary"
                                      disabled
                                    >
                                      <em className="icon ni ni-swap"></em>{" "}
                                      Saving
                                      <div
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-lg btn-primary"
                                      onClick={() => this.submitSignatory()}
                                    >
                                      Save Signatory
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/***** Add Signatory Form *****/}
                        {/***** Delete Signatory Alert *****/}
                        <div
                          class="modal fade"
                          tabindex="-1"
                          id="modalDeleteSignatory"
                        >
                          <div
                            class="modal-dialog modal-dialog-top"
                            role="document"
                          >
                            <div class="modal-content">
                              <a
                                href="#"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                              <div class="modal-header">
                                <h5 class="modal-title">Confirmation</h5>
                              </div>
                              <div class="modal-body">
                                {this.state.signatoryDeleteErrorMessage !==
                                "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <h4>
                                            {
                                              this.state
                                                .signatoryDeleteErrorTitle
                                            }
                                          </h4>
                                          <p>
                                            {
                                              this.state
                                                .signatoryDeleteErrorMessage
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.signatoryDeleteSuccessMessage !==
                                "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-success">
                                        <div className="alert-text">
                                          <h4>Success</h4>
                                          <p>
                                            {
                                              this.state
                                                .signatoryDeleteSuccessMessage
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <p>
                                  Are you certain you wish to delete the
                                  signatory? This action will result in the
                                  removal of the signatory from all associated
                                  agreements.
                                </p>
                              </div>
                              <div
                                class="modal-footer"
                                style={{ justifyContent: "right" }}
                              >
                                <div class="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    class="btn btn-lg btn-light"
                                    style={{ marginRight: "15px" }}
                                    onClick={() => this.closeDeleteSignatory()}
                                  >
                                    No
                                  </button>
                                  {this.state.signatory_button_delete ===
                                  true ? (
                                    <button
                                      className="btn btn-lg btn-primary"
                                      disabled
                                    >
                                      <em className="icon ni ni-swap"></em>{" "}
                                      Deleting
                                      <div
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-lg btn-primary"
                                      onClick={() =>
                                        this.submitDeleteSignatory()
                                      }
                                    >
                                      Yes
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/***** Delete Signatory Alert *****/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Upgrades);
