import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { addServiceType_single } from "./../../../config/api_calls";

import $ from "jquery";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  REACT_APP_AGENT_API_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
// import { Editor } from "@tinymce/tinymce-react";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const auth = new HelperClass();

class AddServiceType extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
      disabled: false,
      product_title: "",
      product_desc: "",
      status: 0,
    };
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  async componentDidMount() {
    this.setState({
      errorMessage: "",
      successMessage: "",
      service_type_id: "",
      tableLoader: true,
    });
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleEditorProductDesc = (event, editor) => {
    const content = editor.getData();
    this.setState({
      product_desc: content,
    });
  };

  // handleEditorProductDesc = (content, editor) => {
  //   this.setState({
  //       product_desc: content,
  //   });
  // };

  formSave = async () => {
    window.scrollTo(0, 0);
    const { product_title, product_desc, status } = this.state;

    var validated = true;

    if (product_title === "") {
      validated = false;
    } else if (product_desc === "") {
      validated = false;
    } else if (status === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await addServiceType_single(
        auth.getAccount(),
        auth.getToken(),
        product_title,
        product_desc,
        status
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "There is some error while Adding the Service Type.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Service Type Added successfully.",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/services/service_type");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  render() {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          REACT_APP_AGENT_API_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() =>
                      this.addMenuHistory("/services/service_type/")
                    }
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Service Type
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add Service Type
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Add Service Type
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="product_title"
                                name="product_title"
                                type="text"
                                label="Service Title"
                                value={this.state.product_title}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          {/* <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="product_desc"
                                name="product_desc"
                                type="text"
                                label="Product  Desc"
                                value={this.state.product_desc}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div> */}
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="status"
                                name="status"
                                select
                                label="Select Status"
                                value={this.state.status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Service Status"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="0" value="0">
                                  Inactive
                                </option>
                                <option key="1" value="1">
                                  Active
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <h6>Product Description</h6>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <CKEditor
                                onReady={(editor) => {
                                  const editableElement =
                                    editor.ui.getEditableElement();
                                  if (
                                    editableElement &&
                                    editableElement.parentElement
                                  ) {
                                    editableElement.parentElement.insertBefore(
                                      editor.ui.view.toolbar.element,
                                      editableElement
                                    );
                                  }

                                  this.editor = editor;
                                }}
                                onError={(error, { willEditorRestart }) => {
                                  if (willEditorRestart) {
                                    if (
                                      this.editor &&
                                      this.editor.ui &&
                                      this.editor.ui.view &&
                                      this.editor.ui.view.toolbar
                                    ) {
                                      this.editor.ui.view.toolbar.element.remove();
                                    }
                                  }
                                }}
                                onChange={(event, editor) =>
                                  this.handleEditorProductDesc(event, editor)
                                }
                                editor={DecoupledEditor}
                                data={this.state.product_desc}
                                config={{
                                  cloudServices: {
                                    tokenUrl: fetchToken,
                                    uploadUrl:
                                      REACT_APP_AGENT_API_URL +
                                      "agents/editor_image_upload",
                                  },
                                  toolbar: {
                                    items: [
                                      "undo",
                                      "redo",
                                      "htmlSupport",
                                      "|",
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "|",
                                      "fontFamily",
                                      "fontSize",
                                      "fontColor",
                                      "fontBackgroundColor",
                                      "|",
                                      "alignment",
                                      "indent",
                                      "outdent",
                                      "|",
                                      "numberedList",
                                      "bulletedList",
                                      "|",
                                      "link",
                                      "imageUpload",
                                      "insertTable",
                                      "|",
                                      "codeBlock",
                                      "blockQuote",
                                      "|",
                                      "removeFormat",
                                      "htmlEmbed",
                                      "fullscreen",
                                    ],
                                  },
                                  language: "en",
                                }}
                              />

                              {/* <Editor
                                id="product_desc"
                                name="product_desc"
                                apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                value={this.state.product_desc}
                                selector="textarea#full-featured"
                                init={{
                                  height: 500,
                                  menubar: true,
                                  plugins: [
                                    "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                  ],
                                  toolbar:
                                    "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                }}
                                onEditorChange={this.handleEditorProductDesc}
                              /> */}
                            </div>
                          </div>
                          <div className="col-lg-4"></div>
                          <div style={{ clear: "both" }}></div>

                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceType);
